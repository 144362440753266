@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.section{
    display:block;
    flex-direction: column;
    background: #ffffff;
    width:100%;
    height:auto;
    position:inherit;
}
.title{
    width:100%;
    font-family: "Montserrat", sans-serif;;
    font-size:50px;
    text-align: center;
    color:#575757;
    position: relative;
    top:80px;
}

.wrapper{
    display:block;
    flex-direction: column;
    position:relative;
    height:auto;
    top:0;
}

.wrapperContact{
    display:block;
    flex-direction: column;
    position:relative;
    height:auto;
    top:50px;
}
.crez{
    width:500px;
    height:800px;
    background: rgb(240, 240, 240);
    position: relative;
    top:120px;
    padding: 20px;
    margin: 0 auto;
    border-radius:20px;
    overflow: auto;
    margin-bottom:20px;
}
.titleCrez{
    font-size:40px;
    width:100%;
    text-align: center;
    background:#575757;
    color:#dddddd;
    margin: 0 auto;
    margin-bottom:20px;
    border-radius:20px;
    z-index:4;
}
p{
    display:flex;
    flex-direction: column;
    margin: 0px 40px 40px 40px;
    position:relative;
    top:0px;
    font-family: "Montserrat", sans-serif;;
    color:#333333;
}
.viziune{
    width:500px;
    height:auto;
    background: rgb(240, 240, 240);
    position: relative;
    top:120px;
    padding: 20px;
    margin: 0 auto;
    border-radius:20px;
    overflow-y: auto;
    margin-bottom:20px;
}
.istoric{
    width:500px;
    height:800px;
    background: rgb(240, 240, 240);
    position: relative;
    top:120px;
    padding: 20px;
    margin: 0 auto;
    border-radius:20px;
    overflow: auto;
    margin-bottom:20px;
}

@media screen and (max-width:450px) {
    .crez,
    .viziune,
    .istoric{
        width:auto;
    }
}