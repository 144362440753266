@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.header{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:relative;
    width:100%;
    height:100vh;
}
.cover {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    filter: brightness(80%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.gradient{
    width:100%;
    height:100%;
    background: linear-gradient(rgba(191, 143, 78, 0.94), rgba(60, 77, 136, 0.94));
    position: relative;
}
.text{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index:2;
    color:#dddddd;
    font-family: "Montserrat", sans-serif;;
    font-size:50px;
    width:100%;
    top:45%;
    text-align: center;
    font-weight: 500;
}
.logo{
    display:flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    width:400px;
    z-index:4;
    top: 200px;
}

@media screen and (max-width: 856px) {
    .text {
        font-size: 50px;
    }
}

@media screen and (max-width: 768px) {
    .text {
        font-size: 50px;
    }
}

@media screen and (max-width: 587px) {
    .text {
        font-size: 42px;
    }
    .cover {
        background-size: auto 100%;
    }
}