@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.contact{
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width:100%;
    height:auto;
    position:inherit;
}
.form{
    display:flex;
    flex-direction: column;
    width: 100%;
}
form{
    display:flex;
    flex-direction: column;
}
input{
    padding:15px;
    font-family: "Montserrat", sans-serif;;
    font-size:15px;
    margin-bottom:9px;
    border-radius:20px;
    border: none;
    border: 1px solid #bf8f4e;
}
textarea{
    max-width:500px;
    min-width:400px;
    max-height:300px;
    min-height:300px;
    padding: 15px;
    font-family: "Montserrat", sans-serif;;
    font-size:15px;
    margin-bottom:9px;
    border-radius:20px;
    border: none;
    border: 1px solid #bf8f4e;
}
.button{
    padding:15px;
    font-family: "Montserrat", sans-serif;;
    font-size:20px;
    background-color:#bf8f4e;
    border: none;
    color:#e7e7e7;
    cursor: pointer;
    border-radius:20px;
}
.formular{
    height:680px;
}
.program{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    height:auto;
}
.adresa{
    display:flex;
    flex-direction: column;
}

@media screen and (max-width:450px) {
    textarea{
        max-width:100%;
        min-width: 100%;
    }
    .program{
        font-size:16px;
        display:flex;
        text-align: center;
    }
}