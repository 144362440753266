@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.nav{
    display:flex;
    width:100%;
    height:74px;
    justify-content: space-between;
    border-bottom: 1px solid #bf8f4e;
    position:fixed;
    z-index:5;
    background-color: rgb(255, 255, 255, 0.96);
}
.nav ul{
    list-style: none;
    display:flex;
}
.nav ul li{
    display:flex;
    align-items:center;
    justify-content: flex-end;
    flex-direction: row;
    padding:20px;
}
.nav ul li a{
    color: #bf8f4e;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;;
    font-size: 20px;
    text-decoration: none;
}
.logoSite{
    display:flex;
    justify-content: flex-end;
    position:relative;
    padding:5px;
    top:2px;
}


.mobileTitleMenu,
.mobileMenu,
.mobileMenu ul,
.mobileMenu ul li{
    display:none;
}
@media screen and (max-width:768px) {
    .nav{
        width:100%;
        position:fixed;
        z-index:5;
        background-color: rgb(255, 255, 255, 0.97);
    }
    .nav ul{
        display:none;
    }
    .mobileTitleMenu{
        display:flex;
        align-items: center;
        padding:20px;
        color: #bf8f4e;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;;
        font-size: 25px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    .mobileMenu{
        width:100%;
        height:200px;
        border-bottom: 1px solid #bf8f4e;
        position:fixed;
        z-index:3;
        top:74px;
        transition: 0.3s ease;
        background-color: rgb(255, 255, 255, 0.97);
    }
    .mobileMenu ul{
        list-style-type: none;
        display:block;
        transition: 0.3s ease;
    }
    .mobileMenu ul li{
        display:flex;
        flex-direction: column;
        transition: 0.3s ease;
    }
}
