@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.footer{
    display:flex;
    flex-direction: column;
    background: #bf8f4e;
    width:100%;
    height:240px;
    position:relative;
    justify-content: center;
    align-items: center;
    top:120px;
}
.titleFooter{
    font-size:20px;
    width: 100%;
    text-align: center;
    font-size:40px;
    color: #ffffff;
    padding: 15px;
    font-family: "Montserrat", sans-serif;;
}
.socials{
    display: flex;
    flex-direction: column;
}
.socials a{
    color:#ffffff;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;;
}
.copyright{
    color:#ffffff;
    margin-top:20px;
    font-family: "Montserrat", sans-serif;;
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position:relative;
    top:20px;
}